import React from 'react';
import { useState } from 'react';
import {
  Box,
  Container,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Card,
  FormControlLabel,
  Checkbox,
  Radio,
  FormControl,
  RadioGroup,
  Grid,
  LinearProgress
} from '@material-ui/core';
import { Block } from 'src/pages/components-overview/Block';
import { MFab } from 'src/components/@material-extend';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import useAuth from 'src/hooks/useAuth';
// utils
// components
// eslint-disable-next-line
export const SelectForm = () => {
  const [progress, setProgress] = useState(25);
  const [disabled, setDisabled] = useState(false);
  const { login } = useAuth();
  const back = () => {
    setProgress((prevProgress) => {
      setDisabled(false);
      return prevProgress - 25;
    });
  };

  const next = () => {
    setProgress((prevProgress) => {
      if (prevProgress == 50) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
      return prevProgress + 25;
    });
  };
  return (
    <Container style={{ justifyContent: 'center', alignItems: 'center', height: '100%', display: 'flex' }}>
      <FormControl>
        <RadioGroup>
          <Block title="Seçenekler">
            <Grid xs={12} sx={{ paddingX: 3, paddingBottom: 3 }}>
              <LinearProgress variant="determinate" value={progress} style={{ height: 10 }} />
            </Grid>
            <Grid container xs={12} style={{ rowGap: 30, display: progress == 25 ? 'flex' : 'none' }}>
              <Grid xs={12} md={4}>
                <Card sx={{ pl: 4, py: 7, mx: 2 }}>
                  <FormControlLabel value="a" control={<Radio />} label="E-Ticaret" />
                </Card>
              </Grid>
              <Grid xs={12} md={4}>
                <Card sx={{ pl: 4, py: 7, mx: 2 }}>
                  <FormControlLabel value="a" control={<Radio />} label="E-Ticaret" />
                </Card>
              </Grid>
              <Grid xs={12} md={4}>
                <Card sx={{ pl: 4, py: 7, mx: 2 }}>
                  <FormControlLabel value="a" control={<Radio />} label="E-Ticaret" />
                </Card>
              </Grid>
              <Grid xs={12} md={4}>
                <Card sx={{ pl: 4, py: 7, mx: 2 }}>
                  <FormControlLabel value="a" control={<Radio />} label="E-Ticaret" />
                </Card>
              </Grid>
              <Grid xs={12} md={4}>
                <Card sx={{ pl: 4, py: 7, mx: 2 }}>
                  <FormControlLabel value="a" control={<Radio />} label="E-Ticaret" />
                </Card>
              </Grid>
              <Grid xs={12} md={4}>
                <Card sx={{ pl: 4, py: 7, mx: 2 }}>
                  <FormControlLabel value="a" control={<Radio />} label="E-Ticaret" />
                </Card>
              </Grid>
            </Grid>
            <Grid container xs={12} style={{ rowGap: 30, display: progress == 50 ? 'flex' : 'none' }}>
              <Grid xs={12} md={4}>
                <Card sx={{ pl: 4, py: 7, mx: 2 }}>
                  <FormControlLabel value="a" control={<Radio />} label="E-Ticaret" />
                </Card>
              </Grid>
              <Grid xs={12} md={4}>
                <Card sx={{ pl: 4, py: 7, mx: 2 }}>
                  <FormControlLabel value="a" control={<Radio />} label="E-Ticaret" />
                </Card>
              </Grid>
              <Grid xs={12} md={4}>
                <Card sx={{ pl: 4, py: 7, mx: 2 }}>
                  <FormControlLabel value="a" control={<Radio />} label="E-Ticaret" />
                </Card>
              </Grid>
              <Grid xs={12} md={4}>
                <Card sx={{ pl: 4, py: 7, mx: 2 }}>
                  <FormControlLabel value="a" control={<Radio />} label="E-Ticaret" />
                </Card>
              </Grid>
              <Grid xs={12} md={4}>
                <Card sx={{ pl: 4, py: 7, mx: 2 }}>
                  <FormControlLabel value="a" control={<Radio />} label="E-Ticaret" />
                </Card>
              </Grid>
              <Grid xs={12} md={4}>
                <Card sx={{ pl: 4, py: 7, mx: 2 }}>
                  <FormControlLabel value="a" control={<Radio />} label="E-Ticaret" />
                </Card>
              </Grid>
            </Grid>

            <Grid container xs={12} style={{ rowGap: 30, display: progress == 75 ? 'flex' : 'none' }}>
              <Grid xs={12} md={4}>
                <Card sx={{ pl: 4, py: 7, mx: 2 }}>
                  <FormControlLabel value="a" control={<Radio />} label="E-Ticaret" />
                </Card>
              </Grid>
              <Grid xs={12} md={4}>
                <Card sx={{ pl: 4, py: 7, mx: 2 }}>
                  <FormControlLabel value="a" control={<Radio />} label="E-Ticaret" />
                </Card>
              </Grid>
              <Grid xs={12} md={4}>
                <Card sx={{ pl: 4, py: 7, mx: 2 }}>
                  <FormControlLabel value="a" control={<Radio />} label="E-Ticaret" />
                </Card>
              </Grid>
              <Grid xs={12} md={4}>
                <Card sx={{ pl: 4, py: 7, mx: 2 }}>
                  <FormControlLabel value="a" control={<Radio />} label="E-Ticaret" />
                </Card>
              </Grid>
              <Grid xs={12} md={4}>
                <Card sx={{ pl: 4, py: 7, mx: 2 }}>
                  <FormControlLabel value="a" control={<Radio />} label="E-Ticaret" />
                </Card>
              </Grid>
              <Grid xs={12} md={4}>
                <Card sx={{ pl: 4, py: 7, mx: 2 }}>
                  <FormControlLabel value="a" control={<Radio />} label="E-Ticaret" />
                </Card>
              </Grid>
            </Grid>
            <Grid xs={12} style={{ justifyContent: 'space-between', display: 'flex', marginTop: 30 }}>
              <MFab
                variant="extended"
                onClick={() => {
                  back();
                }}
                style={{ display: progress === 25 ? 'none' : 'flex' }}
              >
                <FaArrowCircleLeft dis size="24" style={{ marginRight: 10 }} />
                Geri
              </MFab>
              <MFab
                disabled={disabled}
                variant="extended"
                onClick={() => {
                  next();
                }}
              >
                İleri
                <FaArrowCircleRight size="24" style={{ marginLeft: 10 }} />
              </MFab>
            </Grid>
          </Block>
        </RadioGroup>
      </FormControl>
    </Container>
  );
};
