// ----------------------------------------------------------------------

export const fullName = [
  'Fatih Çamcı',
  'Mehmet Demir',
  'Ayşe Kaya',
  'Fatma Şahin',
  'Emre Çelik',
  'Zeynep Koç',
  'Ali Kılıç',
  'Elif Aydın',
  'Hüseyin Çakır',
  'Merve Yıldız',
  'Mustafa Özcan',
  'Hatice Polat',
  'Caner Aslan',
  'Gamze Erdoğan',
  'Burak Güneş',
  'Sevda Korkmaz',
  'Hakan Kılıç',
  'Esra Aksoy',
  'İsmail Kaplan',
  'Büşra Çelik',
  'Oğuzhan Arslan',
  'Neslihan Yıldırım',
  'Furkan Özdemir',
  'Ece Tekin',
  'Yusuf Çelik',
  'Gözde Demir',
  'Kerem Kurt',
  'Serkan Taş',
  'Buse Yıldırım',
  'Uğur Şahin',
  'Pelin Aydın',
  'Cem Yılmaz',
  'Ebru Korkmaz',
  'Sinan Arslan',
  'Ezgi Kılıç',
  'Alper Kaya',
  'Damla Özcan',
  'Kaan Demir',
  'Sibel Güneş',
  'Barış Yıldız'
];

export const firstName = [
  'Ahmet',
  'Mehmet',
  'Ayşe',
  'Fatma',
  'Emre',
  'Zeynep',
  'Ali',
  'Elif',
  'Hüseyin',
  'Merve',
  'Mustafa',
  'Hatice',
  'Caner',
  'Gamze',
  'Burak',
  'Sevda',
  'Hakan',
  'Esra',
  'İsmail',
  'Büşra',
  'Oğuzhan',
  'Neslihan',
  'Furkan',
  'Ece',
  'Yusuf',
  'Gözde',
  'Kerem',
  'Serkan',
  'Buse',
  'Uğur',
  'Pelin',
  'Cem',
  'Ebru',
  'Sinan',
  'Ezgi',
  'Alper',
  'Damla',
  'Kaan',
  'Sibel',
  'Barış'
];

export const lastName = [
  'Yılmaz',
  'Demir',
  'Kaya',
  'Şahin',
  'Çelik',
  'Koç',
  'Kılıç',
  'Aydın',
  'Çakır',
  'Yıldız',
  'Özcan',
  'Polat',
  'Aslan',
  'Erdoğan',
  'Güneş',
  'Korkmaz',
  'Aksoy',
  'Kaplan',
  'Arslan',
  'Tekin',
  'Özdemir',
  'Demir',
  'Kurt',
  'Taş',
  'Yıldırım',
  'Şahin',
  'Aydın',
  'Korkmaz',
  'Arslan',
  'Kılıç',
  'Kaya',
  'Özcan',
  'Demir',
  'Güneş',
  'Yıldız',
  'Taş',
  'Erdoğan',
  'Koç',
  'Şahin',
  'Yılmaz'
];
