import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack5';
// material
import { styled } from '@material-ui/core/styles';
import {
  Button,
  Rating,
  TextField,
  Typography,
  FormHelperText,
  Stack,
  Container,
  Grid,
  Card,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Box,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'src/routes/paths';

import useApiPost, { useApi } from 'src/utils/api';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useCallback, useEffect, useState } from 'react';
import { UploadSingleFile } from 'src/components/upload';
import useApiGet from 'src/utils/apiget';
import useApiPut from 'src/utils/apiput';
import Flmngr from '@flmngr/flmngr-react';

export default function Ayarlar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { themeStretch } = useSettings();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [updateCat, setUpdateCat] = useState([]);
  const ReviewSchema = Yup.object().shape({
    title: Yup.string().required('Bu alanı doldurmak zorunludur.')
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: updateCat[0]?.title ? updateCat[0]?.title : '',
      description: updateCat[0]?.description ? updateCat[0]?.description : '',
      keywords: updateCat[0]?.keywords ? updateCat[0]?.keywords : '',
      slug: updateCat[0]?.slug ? updateCat[0]?.slug : '',
      // banner_image: updateCat[0]?.banner_image ? updateCat[0]?.banner_image : '',
      parent_id: updateCat[0]?.parent_id ? updateCat[0]?.parent_id : '',
      id: updateCat[0]?.id ? updateCat[0]?.id : '',
      status: updateCat[0]?.status ? updateCat[0]?.status : false,
      images: updateCat[0]?.image ? updateCat[0]?.image : ''
    },
    validationSchema: ReviewSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      if (sessionStorage.getItem('catName')?.length > 0) {
        console.log('güncelleme Çalıştı');
        console.log(values.status);
        const data = {
          category_id: sessionStorage.getItem('catName'),
          token: sessionStorage.getItem('token'),
          store_id: sessionStorage.getItem('store_id'),
          title: values.title,
          description: values.description,
          parent_id: values.parent_id,
          image: values.images,
          status: values.status
        };
        await useApiPut(`api/category`, data)
          .then((data) => {
            console.log(data);
            setCatList(data.data);
            sessionStorage.removeItem('catName');
            enqueueSnackbar('Kayıt Başarıyla Güncellendi.', { variant: 'success' });
            navigate(PATH_DASHBOARD.urunler.kategoriler);
          })
          .catch((data) => {
            console.log(data);
            enqueueSnackbar('Hata', { variant: 'error' });
          });
      } else {
        const data = {
          token: sessionStorage.getItem('token'),
          store_id: sessionStorage.getItem('store_id'),
          title: values.title,
          description: values.description,
          parent_id: values.parent_id,
          image: values.images,
          status: values.status
        };
        useApiPost('api/category', data)
          .then((data) => {
            console.log(data);
            navigate(PATH_DASHBOARD.urunler.kategoriler);
            enqueueSnackbar('Kayıt Başarıyla Eklendi.', { variant: 'success' });
          })
          .catch((data) => {
            console.log(data);
            enqueueSnackbar('Hata', { variant: 'error' });
          });
      }
    }
  });

  const handleDelete = (setFieldValue) => {
    setFieldValue('images', '');
  };
  const { errors, touched, resetForm, handleSubmit, isSubmitting, getFieldProps, setFieldValue, values } = formik;
  const handleDrop = useCallback(
    (acceptedFiles) => {
      setFieldValue(
        'banner_image',
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    },
    [setFieldValue]
  );

  const handleRemove = (file) => {
    const filteredItems = values.banner_image.filter((_file) => _file !== file);
    setFieldValue('banner_image', filteredItems);
  };
  const onCancel = () => {
    onClose();
    resetForm();
  };

  // Categories GET
  const [catList, setCatList] = useState([]);

  const getCategories = async () => {
    await useApiGet(`api/category?store_id=${sessionStorage.getItem('store_id')}`)
      .then((data) => {
        setCatList(data.data);
      })
      .catch((data) => {
        console.log(data);
      });
  };
  const getCategoriesUpdate = async () => {
    await useApiGet(
      `api/category?store_id=${sessionStorage.getItem('store_id')}&category_id=${sessionStorage.getItem('catName')}`
    )
      .then((data) => {
        setUpdateCat(data.data);
      })
      .catch((data) => {
        console.log(data);
      })
      .finally(() => {
        setLoading(true);
      });
  };

  useEffect(() => {
    if (sessionStorage.getItem('catName')?.length != 0) {
      getCategoriesUpdate();
    }

    getCategories();
  }, []);

  return (
    <Page title={!loading ? 'Ayarlar | Ekonokobi' : 'Ayarlar | Ekonokobi'}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={'Ayarlar'}
          links={[
            {
              name: 'Ana Sayfa',
              href: PATH_DASHBOARD.root
            }
          ]}
        />
        {loading === true ? (
   <FormikProvider value={formik}>
   <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
     <Grid container spacing={3} justifyContent="center">
       <Grid item xs={12}>
         <Card sx={{ p: 3 }}>
           <Stack spacing={3}>
             <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
               <div style={{ display: values.images.length > 1 ? 'flex' : 'none', flexWrap: 'wrap' }}>
                 {console.log(values.images.length)}
                 <div
                   key={1}
                   style={{
                     position: 'relative',
                     margin: 20,
                     border: '2px solid #000',
                     borderRadius: 100
                   }}
                 >
                   <img
                     src={values.images}
                     alt={`Image ${1}`}
                     style={{ width: 100, height: 100, borderRadius: 100 }}
                   />
                   <button
                     type="button"
                     onClick={() => handleDelete(setFieldValue)}
                     style={{
                       position: 'absolute',
                       top: 0,
                       right: 0,
                       backgroundColor: 'red',
                       color: 'white',
                       border: 'none',
                       borderRadius: '50%',
                       cursor: 'pointer',
                       width: 30,
                       height: 30,
                       display: 'flex',
                       alignItems: 'center',
                       justifyContent: 'center'
                     }}
                   >
                     X
                   </button>
                 </div>
               </div>
             </div>
 
             <Grid container spacing={3}>
               <Grid item md={6}>
                 <TextField
                   fullWidth
                   label="İsim"
                   {...getFieldProps('default_title')}
                   error={Boolean(touched.title && errors.title)}
                   helperText={touched.title && errors.title}
                 />
               </Grid>
               <Grid item md={6}>
                 <TextField
                   fullWidth
                   label="Telefon"
                   {...getFieldProps('phone')}
                   error={Boolean(touched.description && errors.description)}
                   helperText={touched.description && errors.description}
                 />
               </Grid>
               <Grid item md={6}>
                 <TextField
                   fullWidth
                   label="Email"
                   {...getFieldProps('contant_email')}
                   error={Boolean(touched.description && errors.description)}
                   helperText={touched.description && errors.description}
                 />
               </Grid>
               <Grid item md={6}>
                 <TextField
                   fullWidth
                   label="Facebook"
                   {...getFieldProps('facebook')}
                   error={Boolean(touched.description && errors.description)}
                   helperText={touched.description && errors.description}
                 />
               </Grid>
               <Grid item md={6}>
                 <TextField
                   fullWidth
                   label="Instagram"
                   {...getFieldProps('instagram')}
                   error={Boolean(touched.description && errors.description)}
                   helperText={touched.description && errors.description}
                 />
               </Grid>
               <Grid item md={6}>
                 <TextField
                   fullWidth
                   label="Twitter"
                   {...getFieldProps('twitter')}
                   error={Boolean(touched.description && errors.description)}
                   helperText={touched.description && errors.description}
                 />
               </Grid>
               <Grid item md={6}>
                 <TextField
                   fullWidth
                   label="LinkedIn"
                   {...getFieldProps('linkedin')}
                   error={Boolean(touched.description && errors.description)}
                   helperText={touched.description && errors.description}
                 />
               </Grid>
               <Grid item md={6}>
                 <TextField
                   fullWidth
                   label="YouTube"
                   {...getFieldProps('youtube')}
                   error={Boolean(touched.description && errors.description)}
                   helperText={touched.description && errors.description}
                 />
               </Grid>
               <Grid item md={6}>
                 <TextField
                   fullWidth
                   label="WhatsApp"
                   {...getFieldProps('whatsapp')}
                   error={Boolean(touched.description && errors.description)}
                   helperText={touched.description && errors.description}
                 />
               </Grid>
               <Grid item md={6}>
                 <TextField
                   fullWidth
                   label="Telegram"
                   {...getFieldProps('telegram')}
                   error={Boolean(touched.description && errors.description)}
                   helperText={touched.description && errors.description}
                 />
               </Grid>
               <Grid item md={12}>
                 <TextField
                   fullWidth
                   label="Google Analytics"
                   {...getFieldProps('google_analytics')}
                   error={Boolean(touched.description && errors.description)}
                   helperText={touched.description && errors.description}
                 />
               </Grid>
               <Grid item md={12}>
                 <FormControlLabel
                   control={<Switch {...getFieldProps('maintance_mode')} checked={values.status} />}
                   label="Bakım Modu"
                   sx={{ mb: 2 }}
                 />
               </Grid>
             </Grid>
 
             <div style={{ display: values.images.length > 1 ? 'flex' : 'none', flexWrap: 'wrap' }}>
               {console.log(values.images.length)}
               <div
                 key={1}
                 style={{
                   position: 'relative',
                   margin: 20,
                   border: '2px solid #000',
                   borderRadius: 100
                 }}
               >
                 <img
                   src={values.images}
                   alt={`Image ${1}`}
                   style={{ width: 100, height: 100, borderRadius: 100 }}
                 />
                 <button
                   type="button"
                   onClick={() => handleDelete(setFieldValue)}
                   style={{
                     position: 'absolute',
                     top: 0,
                     right: 0,
                     backgroundColor: 'red',
                     color: 'white',
                     border: 'none',
                     borderRadius: '50%',
                     cursor: 'pointer',
                     width: 30,
                     height: 30,
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center'
                   }}
                 >
                   X
                 </button>
               </div>
             </div>
             <LoadingButton
               onClick={() => {
                 Flmngr.open({
                   apiKey: 'gCQ86Ein',
                   urlFileManager: 'https://storgate.dveb.com.tr?user=eczgo.com.tr',
                   urlFiles: 'https://storgate.dveb.com.tr/files/user/eczgo.com.tr/files',
                   isMultiple: false,
                   acceptExtensions: ['png', 'jpg', 'jpeg', 'gif', 'webp'],
                   onFinish: (files) => {
                     const newImage = files[0].url;
                     setFieldValue('images', newImage);
                   }
                 });
               }}
               type="button"
               fullWidth
               variant="contained"
               size="large"
               style={{ position: 'relative', bottom: '12px' }}
             >
               Resim Yükle
             </LoadingButton>
             <Stack direction="row" justifyContent="flex-end">
               <LoadingButton
                 sx={{ paddingLeft: 3, paddingRight: 3 }}
                 type="submit"
                 variant="contained"
                 loading={isSubmitting}
               >
                 Kaydet
               </LoadingButton>
             </Stack>
           </Stack>
         </Card>
       </Grid>
     </Grid>
   </Form>
 </FormikProvider>
 
       
        ) : (
          <></>
        )}
      </Container>
    </Page>
  );
}
