import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { useTheme, styled } from '@material-ui/core/styles';
import {
  Box,
  Card,
  Table,
  Button,
  TableRow,
  Checkbox,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel
} from '@material-ui/core';
// routes
// hooks
// components
import useApiPost, { useApi } from 'src/utils/api';
import { useSnackbar } from 'notistack5';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
import {
  ProductListHead,
  ProductListToolbar,
  ProductMoreMenu
} from 'src/components/_dashboard/e-commerce/product-list';
import Label from 'src/components/Label';
import useSettings from 'src/hooks/useSettings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import useApiDelete from 'src/utils/apidelete';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'image', label: 'Resim', alignRight: false },
  { id: 'name', label: 'Ürünler', alignRight: false },
  { id: 'price', label: 'Fiyat', alignRight: false },
  { id: 'SKU', label: 'SKU', alignRight: false },
  // { id: 'brand', label: 'Marka', alignRight: false },
  // { id: 'category', label: 'Kategori', alignRight: false },
  { id: 'stock', label: 'Stok', alignRight: false },
  { id: 'status', label: 'Durum', alignRight: true },
  { id: '' }
];

const ThumbImgStyle = styled('img')(({ theme }) => ({
  width: 64,
  height: 64,
  objectFit: 'cover',
  margin: theme.spacing(0, 2),
  borderRadius: theme.shape.borderRadiusSm
}));

// ----------------------------------------------------------------------

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// "function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

//  function applySortFilter(array, comparator, query) {
//    const stabilizedThis = array.map((el, index) => [el, index]);
//    stabilizedThis.sort((a, b) => {
//      const order = comparator(a[0], b[0]);
//      if (order !== 0) return order;
//      return a[1] - b[1];
//    });

//    if (query) {
//      return filter(array, (_product) => _product.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//    }

//    return stabilizedThis.map((el) => el[0]);
//  }

// const currencies = [
//   {
//     id: 1,
//     name: 'TL',
//     symbol: '₺'
//   },
//   {
//     id: 2,
//     name: 'USD',
//     symbol: '$'
//   },
//   {
//     id: 3,
//     name: 'EUR',
//     symbol: '€'
//   }
// ];

// function currencySymbol(id) {
//   const c = currencies.find((c) => c.id === id);
//   return c.symbol;
// }"
// ----------------------------------------------------------------------

export default function Urunler() {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [openDeleteHandle, setOpenDeleteHandle] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // NEW API

  const [product, setProduct] = useState([]);

  const getProducts = async () => {
    let data = new URLSearchParams({
      store_id: sessionStorage.getItem("store_id"),
      limit: 20,
      skip: 0
    });
    await useApiPost('api/product-filter', data)
      .then((data) => {
        setProduct(data);
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleDeleteProduct = async (product_id) => {
    await useApiDelete(`api/products?token=${sessionStorage.getItem("token")}&store_id=${sessionStorage.getItem("store_id")}&product_id=${product_id}`)
      .then((res) => {
        setOpenDeleteHandle(false);
        enqueueSnackbar('Kayıt Başarıyla Silindi', { variant: 'success' });
        location.reload();
      })
      .catch((err) => {
        enqueueSnackbar('Kayıt silinirken bir hata ile karşılaşıldı', { variant: 'error' });
        setOpenDeleteHandle(false);
        console.log(err);
      });
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - products.length) : 0;

  const filteredProducts =
    products.length > 0 ? applySortFilter(products, getComparator(order, orderBy), filterName) : [];

  const isProductNotFound = filteredProducts.length === 0;

  useEffect(() => {
    getProducts();
  }, []);
  return (
    <Page title="Ürünler | Ekonokobi">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Ürün Listesi"
          links={[
            { name: 'Anasayfa', href: PATH_DASHBOARD.root },
            {
              name: 'Ürünler',
              href: PATH_DASHBOARD.eCommerce.root
            },
            { name: 'Ürün Listesi' }
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.urunler.tanimlamalar}
              startIcon={<Icon icon={plusFill} />}
            >
              Yeni Ürün Ekle
            </Button>
          }
        />

        <Card>
          <ProductListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell, key) => (
                      <TableCell key={key} align={key === 0 ? 'left' : 'center'}>
                        <TableSortLabel hideSortIcon>{headCell.label}</TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {product.map((index, key) => {
                    // let { id, category, brand, name, image, price, status, price_type } = row;
                    // brand = JSON.parse(brand);
                    // category = JSON.parse(category);
                    console.log(index);

                    return (
                      <TableRow>
                        <TableCell component="th" scope="rosw" padding="none" align="left" style={{ paddingLeft: 0 }}>
                          <Box
                            sx={{
                              py: 1,
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <ThumbImgStyle
                              alt={index.title}
                              src={index.images[0]}
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">{index.title}</TableCell>
                        <TableCell align="center">{index.price} ₺ </TableCell>
                        <TableCell align="center">{index.barcode}</TableCell>
                        {/* <TableCell align="center">Marka</TableCell>
                        <TableCell align="center">Kategori</TableCell> */}
                        <TableCell align="center">{index.stock}</TableCell>
                        <TableCell align="center">
                          <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            color={(index.status === true && 'primary') || (index.status === false && 'error')}
                          >
                            {(index.status === true && 'Aktif') || (index.status === false && 'Pasif')}
                          </Label>
                        </TableCell>
                        <TableCell align="right">
                          <ProductMoreMenu
                            onDelete={() => handleDeleteProduct(index._id)}
                            openDeleteHandle={openDeleteHandle}
                            setOpenDeleteHandle={setOpenDeleteHandle}
                            productId={index}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {/* {isProductNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6}>
                        <Box sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={products.length}
            rowsPerPage={rowsPerPage}
            page={page}
            // onPageChange={handleChangePage}
            // onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
