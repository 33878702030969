import axios from 'axios';

export default function useApiGet(url,) {
  const promise = axios.get(`${process.env.REACT_APP_BASE_URL}${url}`, {
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded",
    // }
  });
  const res = promise.then((res) => res.data);
  return res;
}

