import React, { useCallback, useState } from 'react';
import { Icon } from '@iconify/react';
import { styled } from '@material-ui/core/styles';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  TextField,
  Grid,
} from '@material-ui/core';
// redux
// routes
// hooks
// components
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import { PATH_DASHBOARD } from 'src/routes/paths';
import useSettings from 'src/hooks/useSettings';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack5';
import { LoadingButton } from '@material-ui/lab';
import { UploadSingleFile } from 'src/components/upload';
// ----------------------------------------------------------------------
const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));

export const SliderYonetimiNew = () => {
  const { themeStretch } = useSettings();

  const NewBlogSchema = Yup.object().shape({
    name: Yup.string().required('Başlık Zorunludur'),
    description: Yup.string().required('Açıklama Zorunludur'),
    content: Yup.string().min(100).required('İçerik Zorunludur'),
    cover: Yup.mixed().required('Kapak resmi zorunludur.')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      content: '',
      cover: null,
      title: '',
      description: '',
      keywords: []
    },
    validationSchema: NewBlogSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      console.log(values);
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setFieldValue('cover', {
          ...file,
          preview: URL.createObjectURL(file)
        });
      }
    },
    [setFieldValue]
  );

  return (
    <Page title="Reklam: New Post | Minimal-UI">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Slider Ekle"
          links={[
            { name: 'Ana Sayfa', href: PATH_DASHBOARD.root },
            { name: 'Slider Yönetim', href: PATH_DASHBOARD.reklam.slider },
            { name: 'Slider Ekle' }
          ]}
        />

        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <LabelStyle>Slider Başlığı</LabelStyle>
                    <TextField
                      fullWidth
                      label="Slider Başlığı"
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                    <LabelStyle>Slider Açıklaması</LabelStyle>

                    <TextField
                      fullWidth
                      label="Slider Açıklaması"
                      {...getFieldProps('content')}
                      error={Boolean(touched.content && errors.content)}
                      helperText={touched.content && errors.content}
                    />
                    <LabelStyle>Slider Link</LabelStyle>
                    <TextField
                      fullWidth
                      label="Örn:https://google.com"
                      {...getFieldProps('content')}
                      error={Boolean(touched.content && errors.content)}
                      helperText={touched.content && errors.content}
                    />

                    <div>
                      <LabelStyle>Resim</LabelStyle>
                      <UploadSingleFile
                        maxSize={3145728}
                        accept="image/*"
                        file={values.image}
                        onDrop={handleDrop}
                        error={Boolean(touched.image && errors.image)}
                      />
                      {touched.image && errors.image && (
                        <FormHelperText error sx={{ px: 2 }}>
                          {touched.image && errors.image}
                        </FormHelperText>
                      )}
                    </div>
                  </Stack>
                  <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }}>
                  <LoadingButton fullWidth type="submit" variant="contained" size="large" loading={isSubmitting}>
                    Kaydet
                  </LoadingButton>
                </Stack>
                </Card>

              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
};
