// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import SvgIconStyle from '../../components/SvgIconStyle';
import { FaFileInvoice } from 'react-icons/fa';
import { IoSettings } from 'react-icons/io5';
import { FaSquarePollVertical } from 'react-icons/fa6';
import { GoGraph } from 'react-icons/go';
import { BiSolidCategory } from 'react-icons/bi';
import { FaCircleUser } from 'react-icons/fa6';
import { FaFirstOrderAlt } from 'react-icons/fa';
import { FaBasketShopping } from 'react-icons/fa6';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [{ title: 'Anasayfa', path: PATH_DASHBOARD.general.app, icon: ICONS.ecommerce }]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Yönetim',
    items: [
      {
        title: 'Ürün Yönetimi',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: <FaBasketShopping size={'20px'} />,
        children: [
          { title: 'Ürünler', path: PATH_DASHBOARD.urunler.list },
          { title: 'Ürün Ekle', path: PATH_DASHBOARD.urunler.tanimlamalar },
          // { title: 'Varyant Yönetimi', path: PATH_DASHBOARD.urunler.varyantlar },
          { title: 'Kategori Yönetimi', path: PATH_DASHBOARD.urunler.kategoriler },
          { title: 'Marka Yönetimi', path: PATH_DASHBOARD.urunler.markalar }
          /*           { title: 'Toplu Ürün İşlemleri', path: PATH_DASHBOARD.urunler.topluurun },
          { title: 'Ürün Ayarları', path: PATH_DASHBOARD.urunler.urunayarlari }
 */
        ]
      },

      {
        title: 'Sipariş Yönetimi',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: <FaFirstOrderAlt size={'20px'} />,
        children: [
          { title: 'Siparişler', path: PATH_DASHBOARD.siparisyonetimi.siparisler },
          { title: 'Sipariş Oluştur', path: PATH_DASHBOARD.siparisyonetimi.siparisolustur },
         // { title: 'Terk Edilmiş Siparişler', path: PATH_DASHBOARD.siparisyonetimi.terkedilmissiparisler },
         // { title: 'Sipariş Ayarları', path: PATH_DASHBOARD.siparisyonetimi.siparisayarlari }
        ]
      },
      {
        title: 'Üye Yönetimi',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: <FaCircleUser size={'20px'} />,
        children: [
          { title: 'Üyeler', path: PATH_DASHBOARD.uyeyonetimi.uyeler },
          { title: 'Üye Grupları', path: PATH_DASHBOARD.uyeyonetimi.uyegruplari },
         // { title: 'Üye Ayarları', path: PATH_DASHBOARD.uyeyonetimi.uyeayarlari }
        ]
      },

      {
        title: 'blog',
        path: PATH_DASHBOARD.blog.root,
        icon: <FaFileInvoice size={'20px'} />,
        children: [
          { title: 'Bloglar', path: PATH_DASHBOARD.blog.posts },
          { title: 'Kategoriler', path: PATH_DASHBOARD.blog.categories }
        ]
      },
      {
        title: 'Pazarlama',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: <GoGraph size={'20px'} />,
        children: [
          { title: 'Hediye Çeki', path: PATH_DASHBOARD.pazarlama.hediyeceki },
          { title: 'Kampanyalar', path: PATH_DASHBOARD.pazarlama.kampanyalar },
          { title: 'E-Posta Pazarlama', path: PATH_DASHBOARD.pazarlama.epostapazarlama },
          { title: 'Sms Pazarlama', path: PATH_DASHBOARD.pazarlama.smspazarlama }
        ]
      },
      {
        title: 'reklam',
        icon: <FaFirstOrderAlt size={'20px'} />,
        path: PATH_DASHBOARD.reklam.root,
        children: [
          {title: 'Slider Yönetimi', path: PATH_DASHBOARD.reklam.slider },
          {title: 'Banner Yönetimi', path: PATH_DASHBOARD.reklam.banner },
        ]
      },
      {
        title: 'Raporlar',
        path: PATH_DASHBOARD.raporlar.root,
        icon: <FaSquarePollVertical size={'20px'} />
      },
      {
        title: 'Ayarlar',
        path: PATH_DASHBOARD.ayarlar.root,
        icon: <IoSettings size={'20px'} />
      }
    ]
  }
];

export default sidebarConfig;
