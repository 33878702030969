import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack5';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import { Block } from 'src/pages/components-overview/Block';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Alert,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Grid,
  InputLabel,
  FormLabel
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// routes
import { PATH_AUTH, PATH_DASHBOARD, PATH_DOCS, PATH_PAGE } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import { MIconButton } from '../../@material-extend';
import useApiPost from 'src/utils/api';

// utils
// components
// eslint-disable-next-line

const style = {
  justifyContent: 'center',
  maxWidth: '50%'
};
export const SelectFormNew = () => {
  const navigate = useNavigate();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [Token, setToken] = useState();
  const LoginSchema = Yup.object().shape({
    domain: Yup.string().required('Domain alanı zorunludur'),
    user_name: Yup.string().required('Kullanıcı Adı alanı zorunludur'),
    site_name: Yup.string().required('Site Adı zorunludur')
  });
  const Create = async (values) => {
    let data = new URLSearchParams({
      domain: values.domain,
      user_name: values.user_name,
      site_name: values.site_name,
      site_language: 'tr',
      token: sessionStorage.getItem("token")
    });
    await useApiPost('api/create-site/add', data)
      .then((data) => {
        console.log(data);
        sessionStorage.setItem("store_id" , data.store_id)
        navigate(PATH_DASHBOARD.general.app);
      })
      .catch((data) => {
        console.log(data);
      });
  };


  const formik = useFormik({
    initialValues: {
      domain: "",
      user_name: "",
      site_name: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      
      try {
        await Create(values);
        // await login(values.email, values.password);
        enqueueSnackbar('Giriş Başarılı', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error);
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.message });
        }
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Grid style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100%' }}>
      <Grid style={{ minWidth: 500 }}>
        <Block title="Siteni Oluştur">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
                <TextField
                  fullWidth
                  type="text"
                  label="domain.com"
                  {...getFieldProps('domain')}
                  error={Boolean(touched.domain && errors.domain)}
                  helperText={touched.domain && errors.domain}
                />
                <TextField
                  fullWidth
                  type="text"
                  label="Kullanıcı Adı"
                  {...getFieldProps('user_name')}
                  error={Boolean(touched.user_name && errors.user_name)}
                  helperText={touched.user_name && errors.user_name}
                />
                <TextField
                  fullWidth
                  type="text"
                  label="Site İsmi"
                  {...getFieldProps('site_name')}
                  error={Boolean(touched.site_name && errors.site_name)}
                  helperText={touched.site_name && errors.site_name}
                />
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                style={{ marginTop: 20 }}
              >
                Oluştur
              </LoadingButton>
            </Form>
          </FormikProvider>
        </Block>
      </Grid>
    </Grid>
  );
};
