import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Card,
  FormControlLabel,
  Checkbox,
  Radio,
  FormControl,
  RadioGroup,
  Grid,
  LinearProgress,
  Button
} from '@material-ui/core';
import { Block } from 'src/pages/components-overview/Block';
import useApiPost from 'src/utils/api';
import { PATH_AUTH, PATH_DASHBOARD } from 'src/routes/paths';
import { ArrowForwardIosOutlined, PlusOneOutlined, StorefrontOutlined } from '@material-ui/icons';
import { FaPlus } from 'react-icons/fa';
import { BiPlus, BiPlusCircle } from 'react-icons/bi';
// utils
// components
// eslint-disable-next-line
const style = {
  minHeight: 'auto',
  marginBottom: 3
};

export const SelectMenu = () => {
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const user = async () => {
    const tokens = new URLSearchParams({
      token: sessionStorage.getItem('token')
    });
    useApiPost('api/user/services', tokens)
      .then((data) => {
        console.log(data.data);
        setUserList(data.data);
      })
      .catch((data) => {
        console.log(data);
      });
  };
  useEffect(() => {
    user();
  }, []);
  const handleClick = (key) => {
    console.log("session ", key);
    sessionStorage.setItem('store_id', key);

    navigate(PATH_DASHBOARD.general.app);
  };
  return (
    <Container
      style={{ justifyContent: 'center', alignItems: 'center', height: '100%', maxWidth: '35%', marginTop: 50 }}
    >
      {/* <Block sx={style}>
      <Typography gutterBottom variant="h6">
          Merhaba,
          <br /> Ekonokobi!
        </Typography>
      </Block> */}
      <Block title="Hoşgeldiniz">
        <Grid container style={{ flexDirection: 'row' }}>
          {userList.map((index, key) => {
            return (
              <Grid container sx={{ marginTop: 1, padding: 1 }} key={key}>
                <Button
                  variant="outlined"
                  fullWidth
                  style={{ color: '#838381', borderColor: '#838381' }}
                  onClick={() => {
                    handleClick(index?._id);
                  }}
                >
                  <StorefrontOutlined style={{ fontSize: 40 }} />
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingLeft: 20 }}>
                    <text style={{ textAlign: 'start' }}>{index.site_name}</text>
                    <text style={{ textAlign: 'start' }}>{index.domain}</text>
                  </div>
                  <ArrowForwardIosOutlined />
                </Button>
              </Grid>
            );
          })}
          <Grid container sx={{ marginTop: 1, padding: 1 }}>
            <Button
              variant="outlined"
              style={{ color: '#b80bb8', borderColor: '#b80bb8' }}
              fullWidth
              onClick={() => {
                navigate(PATH_AUTH.selectFormNew);
              }}
            >
              <BiPlus style={{ fontSize: 40 }} />
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingLeft: 20 }}>
                <text style={{ textAlign: 'start' }}>Yeni Mağaza Oluştur</text>
              </div>
              <ArrowForwardIosOutlined />
            </Button>
          </Grid>
        </Grid>
      </Block>
    </Container>
  );
};
