import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  Typography
} from '@material-ui/core';

// ----------------------------------------------------------------------

export default function CategoryMoreMenu({ openDeleteHandle, setOpenDeleteHandle, onDelete, productId }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={() => setOpenDeleteHandle(true)} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Sil" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem onClick={() => { sessionStorage.setItem("catName", productId) }} component={RouterLink} to={`/urunler/kategori-ekle/${productId}`} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Düzenle" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <Dialog fullWidth open={openDeleteHandle} onClose={() => setOpenDeleteHandle(false)}>
          <DialogTitle>Ürün Sil</DialogTitle>
          <DialogContent>
            <Typography sx={{ marginTop: 2 }}>Kayıt silinecek, emin misin?</Typography>
            <Box display={'flex'} justifyContent={'end'} gap={2} marginTop={4}>
              <Button variant="contained" color="error" onClick={onDelete}>
                Sil
              </Button>
              <Button variant="contained" color="inherit" onClick={() => setOpenDeleteHandle(false)}>
                İptal
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Menu>
    </>
  );
}
