// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  selectForm: path(ROOTS_AUTH, "/select-form"),
  selectMenu: path(ROOTS_AUTH, "/select-menu"),
  selectFormNew: path(ROOTS_AUTH, "/select-form-new")
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking')
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  category: {
    root: path(ROOTS_DASHBOARD, '/category'),
    list: path(ROOTS_DASHBOARD, '/category/list')
  },
  urunler: {
    root: path(ROOTS_DASHBOARD, '/urunler'),
    list: path(ROOTS_DASHBOARD, '/urunler/urunler'),
    tanimlamalar: path(ROOTS_DASHBOARD, '/urunler/tanimlamalar'),
    topluurun: path(ROOTS_DASHBOARD, '/urunler/toplu-urun-islemleri'),
    urunayarlari: path(ROOTS_DASHBOARD, '/urunler/urun-ayarlari'),
    yenikullanici: path(ROOTS_DASHBOARD, '/urunler/yeni-kullanici'),
    varyantlar: path(ROOTS_DASHBOARD, '/urunler/varyantlar'),
    varyantTanimlama: path(ROOTS_DASHBOARD, '/urunler/varyant-tanimla'),
    kategoriler:path(ROOTS_DASHBOARD, 'urunler/kategoriler'),
    kategoriEkle:path(ROOTS_DASHBOARD, 'urunler/kategori-ekle'),
    markalar:path(ROOTS_DASHBOARD, 'urunler/markalar'),
    markaEkle:path(ROOTS_DASHBOARD, 'urunler/marka-ekle'),
  },
  siparisyonetimi: {
    root: path(ROOTS_DASHBOARD, '/siparisyonetimi'),
    siparisler: path(ROOTS_DASHBOARD, '/siparisyonetimi/siparisler'),
    siparisolustur: path(ROOTS_DASHBOARD, '/siparisyonetimi/siparis-olustur'),
    terkedilmissiparisler: path(ROOTS_DASHBOARD, '/siparisyonetimi/terk-edilmis-siparisler'),
    siparisayarlari: path(ROOTS_DASHBOARD, '/siparisyonetimi/siparis-ayarlari')
  },
  uyeyonetimi: {
    root: path(ROOTS_DASHBOARD, '/uyeyonetimi'),
    uyeler: path(ROOTS_DASHBOARD, '/uyeyonetimi/uyeler'),
    uyegruplari: path(ROOTS_DASHBOARD, '/uyeyonetimi/uye-gruplari'),
    uyeayarlari: path(ROOTS_DASHBOARD, '/uyeyonetimi/uye-ayarlari'),
    uyegrubuekle: path(ROOTS_DASHBOARD, '/uyeyonetimi/uye-grubu-ekle')

  },

  blogyonetimi: {
    root: path(ROOTS_DASHBOARD, '/blogyonetimi'),
    blogyazilarim: path(ROOTS_DASHBOARD, '/blogyonetimi/blogyazilari'),
    blogkategorileri: path(ROOTS_DASHBOARD, '/blogyonetimi/blog-kategorileri'),
    blogayarlari: path(ROOTS_DASHBOARD, '/blogyonetimi/blog-ayarlari')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),
    categories: path(ROOTS_DASHBOARD, '/blog/categories'),  
  },
  pazarlama: {
    root: path(ROOTS_DASHBOARD, '/pazarlama'),
    hediyeceki: path(ROOTS_DASHBOARD, '/pazarlama/hediye-ceki'),
    kampanyalar: path(ROOTS_DASHBOARD, '/pazarlama/kampanyalar'),
    epostapazarlama: path(ROOTS_DASHBOARD, '/pazarlama/eposta-pazarlama'),
    smspazarlama: path(ROOTS_DASHBOARD, '/pazarlama/sms-pazarlama')
  },
  reklam: {
    root: path(ROOTS_DASHBOARD, '/reklam'),
    slider: path(ROOTS_DASHBOARD, '/reklam/slider'),
    slidernew: path(ROOTS_DASHBOARD, '/reklam/slidernew'),
    banner: path(ROOTS_DASHBOARD, '/reklam/banner'),
    bannernew: path(ROOTS_DASHBOARD, '/reklam/bannernew'),
  },
  ayarlar: {
    root: path(ROOTS_DASHBOARD, '/ayarlar'),
  },
  raporlar: {
    root: path(ROOTS_DASHBOARD, '/raporlar'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
