import axios from 'axios';

export const useApi = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      apikey: '123123'
    }
  });
};
export const useApiFile = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      'Content-Type': 'multipart/form-data',
      apikey: '123123'
    }
  });
};
export default function useApiPost(url, data) {
  const promise = axios.post(`${process.env.REACT_APP_BASE_URL}${url}`, data, {
    headers: {
      // "Content-Type": "application/x-www-form-urlencoded",
    }
  });
  const res = promise.then((res) => res.data);
  return res;
};


