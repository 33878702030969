import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Box,
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
import { useTheme } from '@material-ui/core/styles';

// components
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { ProductListToolbar } from '../../../components/_dashboard/e-commerce/product-list';
import { useApi } from 'src/utils/api';
import { useSnackbar } from 'notistack5';
import VariantListHeader from 'src/components/_dashboard/e-commerce/product-list/VariantListHeader';
import CategoryMoreMenu from 'src/components/_dashboard/e-commerce/product-list/CategoryMoreMenu';
import useApiGet from 'src/utils/apiget';
import Label from 'src/components/Label';
import useApiDelete from 'src/utils/apidelete';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'İsim', alignRight: false },
  { id: 'order', label: 'Sipariş No', alignRight: false },
  { id: 'price', label: 'Tutar', alignRight: false },
  { id: 'address', label: 'Adres', alignRight: false },
  { id: 'status', label: 'Durum', alignRight: false }
  // { id: '' }
];

export default function Siparisler() {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const [products, setProducts] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDeleteHandle, setOpenDeleteHandle] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [searchData, setSearchData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const handleFilterByName = (event) => {
    // setSearchText(event.target.value);
    // const searchList = orderList.filter((index) =>
    //   console.log(index),
    //   index?.name.toLowerCase().includes(event.target.value.toLowerCase())
    // );
    // setSearchData(searchList);
  };
  // const handleDeleteProduct = async (catid) => {
  //   const data = new URLSearchParams({
  //     category_id: catid,
  //     token: sessionStorage.getItem('token'),
  //     store_id: sessionStorage.getItem('store_id')
  //   });
  //   await useApiDelete('api/category', data)
  //     .then((res) => {
  //       setOpenDeleteHandle(false);
  //       enqueueSnackbar('Kayıt Başarıyla Silindi', { variant: 'success' });
  //       location.reload();
  //     })
  //     .catch((err) => {
  //       enqueueSnackbar('Kayıt silinirken bir hata ile karşılaşıldı', { variant: 'error' });
  //       setOpenDeleteHandle(false);
  //       console.log(err);
  //     });
  // };

  const orderGet = async () => {
    console.log('api  Get çalıştı');
    await useApiGet(
      `api/checkout/admin?store_id=${sessionStorage.getItem('store_id')}&token=${sessionStorage.getItem('token')}`
    )
      .then((data) => {
        console.log(data.orders);
        setOrderList(data.orders);
      })
      .catch((data) => {
        console.log(data);
      });
  };
  useEffect(() => {
    orderGet();
  }, []);

  return (
    <Page title="Kategori Yönetimi | Ekonokobi">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Siparişler"
          links={[
            { name: 'Anasayfa', href: PATH_DASHBOARD.root },
            {
              name: 'Ürünler',
              href: PATH_DASHBOARD.urunler.list
            },
            { name: 'Siparişler' }
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.urunler.kategoriEkle}
              startIcon={<Icon icon={plusFill} />}
            >
              Yeni Kategori Ekle
            </Button>
          }
        />

        <Card>
          <ProductListToolbar onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <VariantListHeader headLabel={TABLE_HEAD} rowCount={orderList.length} numSelected={orderList.length} />
                <TableBody>
                  {searchText.length === 0
                    ? orderList.map((index, key) => {
                        // const isItemSelected = selected.indexOf(index.title) !== -1;
                        return (
                          <TableRow
                            hover
                            key={index._id}
                            tabIndex={0}
                            role="none"
                            // selected={isItemSelected}
                            // aria-checked={isItemSelected}
                          >
                            <TableCell component="th" scope="row" padding="none" align="left">
                              <Box
                                sx={{
                                  py: 1,
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <Typography variant="subtitle2" noWrap>
                                  {index.name}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none" align="left">
                              <Box
                                sx={{
                                  py: 1,
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <Typography variant="subtitle2" noWrap>
                                  {key + 1}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none" align="left">
                              <Box
                                sx={{
                                  py: 1,
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <Typography variant="subtitle2" noWrap>
                                  {index.total} TL
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none" align="left">
                              <Box
                                sx={{
                                  py: 1,
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <Typography variant="subtitle2" noWrap>
                                  {index.billing_address.address}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell align="left">
                              <Label
                              // variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                              // color='primary'
                              >
                                {' '}
                                Alındı
                              </Label>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : searchData.map((index, key) => {
                        // const isItemSelected = selected.indexOf(index.title) !== -1;
                        return (
                          <TableRow
                            hover
                            key={index._id}
                            tabIndex={0}
                            role="none"
                            // selected={isItemSelected}
                            // aria-checked={isItemSelected}
                          >
                            <TableCell component="th" scope="row" padding="none" align="left">
                              <Box
                                sx={{
                                  py: 1,
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <Typography variant="subtitle2" noWrap>
                                  {index.title}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none" align="left">
                              <Box
                                sx={{
                                  py: 1,
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <Typography variant="subtitle2" noWrap>
                                  {index?.parent_id === undefined ? 'Yok' : index.parent_id}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell align="left">
                              <Label
                                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                                color={(index.status === true && 'primary') || (index.status === false && 'error')}
                              >
                                {index.status === true ? 'Aktif' : 'Pasif'}
                              </Label>
                            </TableCell>

                            <TableCell align="right">
                              <CategoryMoreMenu
                                onDelete={() => handleDeleteProduct(index._id)}
                                openDeleteHandle={openDeleteHandle}
                                setOpenDeleteHandle={setOpenDeleteHandle}
                                productId={index._id}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}

                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
                {/* {isProductNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6}>
                        <Box sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={products.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
