import React from 'react';
import { Icon } from '@iconify/react';
import {  styled } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useApi } from 'src/utils/api';

// material
import { useTheme } from '@material-ui/core/styles';
import {
  Card,
  Table,
  Stack,
  Box,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Modal,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TableContainer,
  TablePagination,
  TextField,
  Autocomplete,
  CloudUploadIcon,
  TableHead,
  TableSortLabel
} from '@material-ui/core';
// redux
// routes
import { PATH_DASHBOARD } from '../routes/paths';
// hooks
import useSettings from '../hooks/useSettings';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { UserMoreMenu } from '../components/_dashboard/user/list';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'İsim', alignRight: false },
  { id: 'tittle', label: 'Başlık', alignRight: false },
  { id: 'description', label: 'Açıklama', alignRight: false },
  { id: 'keyword', label: 'Anahtar Kelimeler', alignRight: false },
  { id: 'slug', label: 'Slug', alignRight: false },
  { id: 'edit', label: 'Düzenle', alignRight: false }
];

export default function Categories() {
  const { themeStretch } = useSettings();
  const [open, setOpen] = React.useState(false);
  const [age, setAge] = React.useState('');

  const [categories, setCategories] = useState([]);
  const [values, setValues] = useState({
    name: '',
    title: '',
    description: '',
    keywords: '',
    slug: 'sdfgsdgf',
    banner_image: 'asdfasdf'
  });
  const getAllCategories = () => {
    useApi()
      .get('blog/category')
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllCategories();
  }, []);
  // GET

  const handleClick = () => {
    useApi()
      .post('blog/category', values)
      .then((res) => {
        toast.success('Kategori başarıyla eklendi.');
        getAllCategories();
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Kategori eklenirken bir hata ile karşılaşıldı');
      });
  };

  const handleOpen = () => setOpen(true);


  const handleDeleteCategory = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true
      })
      .then((result) => {
        if (result.isConfirmed) {
          useApi()
          .delete('blog/category', { id: id })
    
          .then((res) => {
            toast.success('Kategori başarıyla silindi.');
            getAllCategories();
          })
          .catch((err) => {
            console.log(err);
            toast.error('Kategori silinirken bir hata ile karşılaşıldı');
          });
        }
      });
    
  };
  return (
    <Page title="Kategoriler | Ekonokobi">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Kategoriler"
          links={[
            { name: 'Anasayfa', href: PATH_DASHBOARD.root },
            { name: 'Blog', href: PATH_DASHBOARD.category },
            { name: 'Kategoriler' }
          ]}
          action={
            <Button onClick={handleOpen} variant="contained" startIcon={<Icon icon={plusFill} />}>
              Yeni Kategori Ekle
            </Button>
          }
        />
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                        <TableSortLabel hideSortIcon>{headCell.label}</TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categories.map((category, id) => {
                    const { name, title, description, keywords } = category;
                    return (
                      <TableRow>
                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar
                              alt={name}
                              src={'http://localhost:3000/static/mock-images/products/product_24.jpg'}
                            />
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{title}</TableCell>
                        <TableCell align="left">{description}</TableCell>
                        <TableCell align="left">{keywords}</TableCell>
                        <TableCell align="left">{category.slug}</TableCell>

                        <TableCell align="right">
                          <UserMoreMenu onDelete={() => handleDeleteCategory(id)} userName={name} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {categories.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}></TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}