import React from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import {
  Card,
  Table,
  Stack,
  Box,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Modal,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TableContainer,
  TablePagination,
  TextField,
  Autocomplete,
  CloudUploadIcon,
  TableHead,
  TableSortLabel
} from '@material-ui/core';
// redux
// routes
// hooks
// components
import { UserMoreMenu } from 'src/components/_dashboard/user/list';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import Scrollbar from 'src/components/Scrollbar';
import { PATH_DASHBOARD } from 'src/routes/paths';
import useSettings from 'src/hooks/useSettings';
import { Link } from 'react-router-dom';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'order', label: '#', alignRight: false },
  { id: 'title', label: 'Başlık', alignRight: false },
  { id: 'image', label: 'Görsel', alignRight: false },
  { id: 'description', label: 'Açıklama', alignRight: false },
  { id: 'slug', label: 'Durum', alignRight: false },
  { id: 'edit', label: 'İşlemler', alignRight: false }
];

export const SliderYonetimi = () => {
  const { themeStretch } = useSettings();
  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Slider Yönetimi"
          links={[{ name: 'Anasayfa', href: PATH_DASHBOARD.root }, { name: 'Slider' }]}
          action={
            <Link to="/reklam/slidernew" style={{ textDecoration: 'none', color: 'white' }}>
              <Button variant="contained" startIcon={<Icon icon={plusFill} />}>
                Yeni Slider Ekle
              </Button>
            </Link>
          }
        />
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                        <TableSortLabel hideSortIcon>{headCell.label}</TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {/* <TableBody>
                  {categories.map((category, id) => {
                    const { name, title, description, keywords } = category;
                    return (
                      <TableRow>
                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar
                              alt={name}
                              src={'http://localhost:3000/static/mock-images/products/product_24.jpg'}
                            />
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{title}</TableCell>
                        <TableCell align="left">{description}</TableCell>
                        <TableCell align="left">{keywords}</TableCell>
                        <TableCell align="left">{category.slug}</TableCell>

                        <TableCell align="right">
                          <UserMoreMenu onDelete={() => handleDeleteCategory(id)} userName={name} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody> */}
                {/* {categories.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}></TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
};
